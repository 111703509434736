import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Bio from '../components/Bio';

const ListItemStyles = styled.div`
  margin-bottom: var(--spacing-8);
  margin-top: var(--spacing-8);

  h2 {
    font-size: var(--fontSize-4);
    color: var(--color-primary);
    margin-bottom: var(--spacing-2);
    margin-top: var(--spacing-0);
  }
`;

export default function IndexPage({ data, location }) {
  return (
    <Layout location={location}>
      <SEO title="Home" />
      <Bio />

      {data.allMarkdownRemark.edges.map(({ node }) => (
        <ListItemStyles key={node.id}>
          <h2>
            <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
          </h2>
          <small>{node.frontmatter.date}</small>
          <p>{node.excerpt}</p>
        </ListItemStyles>
      ))}
    </Layout>
  );
}

// starter project uses frontmatter.description and falls back to excerpt if its
// not there. May want to think about that for the future.
export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/posts/" } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`;
